define('ember-sort-filter-table/components/sort-filter-table', ['exports', 'ember-sort-filter-table/templates/components/sort-filter-table', 'ember-decorators/object', 'ember-decorators/object/computed', 'ember-sort-filter-table/utils'], function (exports, _sortFilterTable, _object, _computed, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _desc, _value, _obj, _init;

  var values = Object.values;
  exports.default = Ember.Component.extend((_dec = (0, _object.computed)('table.rows', 'filter'), _dec2 = (0, _object.computed)('rows'), _dec3 = (0, _object.computed)('headings'), _dec4 = (0, _object.computed)('headings'), _dec5 = (0, _object.computed)('isAscending'), _dec6 = (0, _computed.alias)('labels.length'), (_obj = {
    layout: _sortFilterTable.default,
    tagName: 'table',
    classNames: ['sort-filter-table table'],

    /**
     Config: Signal if filter input field should be included
      @property filterable
     @type Bool
     @public
    */
    filterable: true,

    /**
      A placeholder to display in the filter input field
       @property filterPlaceholder
      @public
      @type String
    */
    filterPlaceholder: 'filter',

    /**
      A query or filter provided by input
       @property filter
      @public
      @type String
    */
    filter: '',

    rows: function rows(_rows, filterQuery) {
      return Ember.A(_rows.filter(function (row) {
        var rowValues = values(row);
        var filterExp = new RegExp(filterQuery, 'ig');
        Ember.set(row, '_filtered', !filterExp.test(rowValues.join(',').toLowerCase()));
        return row;
      }));
    },
    headings: function headings(rows) {
      return Ember.A((0, _utils.primitiveKeys)(rows[0]));
    },
    labels: function labels(headings) {
      var _this = this;

      return Ember.A(headings.map(function (item) {
        return Ember.Object.create({
          _key: item,
          name: _this._handleSeparators(item),
          _sortClass: 'asc',
          _isPrivate: (0, _utils.isPrivateKey)(item)
        });
      }));
    },


    /**
      Store separator for headings
       @property _separator
      @type String
      @private
    */
    _separator: null,

    /**
      Replaces hyphens or underscores with spaces (used to prettify headings)
       @method _handleSeparators
      @private
    */
    _handleSeparators: function _handleSeparators(str) {
      var isPrivate = str[0] === '_';

      if (!isPrivate) {
        var separator = str.match(/[-\s_]/g);
        var camelCase = str.match(/[A-Z]([A-Z0-9]*[a-z][a-z0-9]*[A-Z]|[a-z0-9]*[A-Z][A-Z0-9]*[a-z])[A-Za-z0-9]*/);
        separator = camelCase || separator;

        if (separator && separator.length) {
          // save current separator
          Ember.set(this, '_separator', separator[0]);

          // return label without separator for display
          return str.replace(new RegExp(separator[0], 'g'), ' ');
        } else {
          // pass through
          return str;
        }
      }
    },
    sortLabel: function sortLabel(headings) {
      return headings[0];
    },


    /**
      Signal wether current sort param is asc or desc
       @property isAscending
      @type Bool
      @private
    */
    isAscending: true,

    _direction: function _direction(isAscending) {
      return isAscending ? '' : ':desc';
    },
    totalColumns: 0,

    /**
      * Key to sorty by
      *
      * @property groupSortKey
      * @type String
      * @public
      */
    groupSortKey: 'none',

    /**
      * Sort direction (asc/desc)
      *
      * @property groupSortDirection
      * @type String
      * @public
      */
    groupSortDirection: 'desc',

    actions: {

      /**
        Apply label as sort
         @method sortByLabel
        @private
      */
      sortByLabel: function sortByLabel(label) {
        var sortName = Ember.get(label, 'name').replace(/ /g, Ember.get(this, '_separator'));

        // toggle sort direction
        this.toggleProperty('isAscending');

        // provide direction class
        Ember.set(label, '_sortClass', Ember.get(this, 'isAscending') ? 'asc' : 'desc');

        // update sort label prop
        Ember.set(this, 'sortLabel', sortName);
      },


      /**
        Send values up to actions
         @method sendEditAction
        @private
      */
      sendEditAction: function sendEditAction(row, key, value, actionType) {
        var action = Ember.get(this, actionType);

        Ember.set(row, '_editingRow', null);
        if (action) {
          action({
            row: row,
            key: key,
            value: value
          });
        }
      },


      /**
        Display input field for editing
         @method
        @private
      */
      editValue: function editValue(row, key) {
        Ember.get(this, 'rows').setEach('_editingRow', null);
        Ember.set(row, '_editingRow', key);
      }
    }
  }, (_applyDecoratedDescriptor(_obj, 'rows', [_dec], Object.getOwnPropertyDescriptor(_obj, 'rows'), _obj), _applyDecoratedDescriptor(_obj, 'headings', [_dec2], Object.getOwnPropertyDescriptor(_obj, 'headings'), _obj), _applyDecoratedDescriptor(_obj, 'labels', [_dec3], Object.getOwnPropertyDescriptor(_obj, 'labels'), _obj), _applyDecoratedDescriptor(_obj, 'sortLabel', [_dec4], Object.getOwnPropertyDescriptor(_obj, 'sortLabel'), _obj), _applyDecoratedDescriptor(_obj, '_direction', [_dec5], Object.getOwnPropertyDescriptor(_obj, '_direction'), _obj), _applyDecoratedDescriptor(_obj, 'totalColumns', [_dec6], (_init = Object.getOwnPropertyDescriptor(_obj, 'totalColumns'), _init = _init ? _init.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init;
    }
  }), _obj)), _obj)));
});