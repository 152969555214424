define('ember-sort-filter-table/utils', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  /**
    Object keys with a leading underscore should be designated as private
  
    @method isPrivateKey
    @private
    @returns Bool
  */
  var isPrivateKey = exports.isPrivateKey = function isPrivateKey(key) {
    return (/_/.test(key[0])
    );
  };

  /**
    Extract only keys that point to primitive types
    (exluding null and undefined)
  
    @method primitiveKeys
    @private
    @returns Array
  */
  var primitiveKeys = exports.primitiveKeys = function primitiveKeys(obj) {
    var arr = [];
    for (var i in obj) {
      if (obj.hasOwnProperty(i)) {
        var val = obj[i];
        if (typeof val === 'string' || typeof val === 'boolean' || typeof val === 'number') {
          arr.push(i);
        }
      }
    }
    return arr;
  };

  /**
    Object values polyfill
    https://github.com/tc39/proposal-object-values-entries/blob/master/polyfill.js
  */
  var reduce = Function.bind.call(Function.call, Array.prototype.reduce);
  var isEnumerable = Function.bind.call(Function.call, Object.prototype.propertyIsEnumerable);
  var concat = Function.bind.call(Function.call, Array.prototype.concat);
  var keys = Object.keys;

  if (!Object.values) {
    Object.values = function values(O) {
      return reduce(keys(O), function (v, k) {
        return concat(v, typeof k === 'string' && isEnumerable(O, k) ? [O[k]] : []);
      }, []);
    };
  }
});