define('ember-rapid-forms/components/form-group', ['exports', 'ember', 'ember-rapid-forms/templates/components/form-group'], function (exports, _ember, _emberRapidFormsTemplatesComponentsFormGroup) {
  exports['default'] = _ember['default'].Component.extend({
    layout: _emberRapidFormsTemplatesComponentsFormGroup['default'],
    tagName: '',
    property: null,
    id: _ember['default'].computed('cid', 'property', {
      get: function get() {
        if (this.get('cid')) {
          return this.get('cid');
        } else {
          return this.get('property') + '-' + this.elementId;
        }
      }
    })
  });
});