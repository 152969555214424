define("ember-sort-filter-table/helpers/is-object", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isObject = isObject;
  function isObject(params /*, hash*/) {
    var obj = params[0];
    return obj && obj.toString() === '[object Object]';
  }

  exports.default = Ember.Helper.helper(isObject);
});