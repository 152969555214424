define('ember-cli-google-recaptcha/components/g-recaptcha-invisible', ['exports', 'ember-cli-google-recaptcha/-private/g-recaptcha-base'], function (exports, _gRecaptchaBase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _gRecaptchaBase.default.extend({
    badge: 'bottomright',

    type: 'image',

    size: 'invisible',

    _extendedOptions: Ember.computed('badge', function () {
      var badge = this.get('badge');
      return { badge: badge };
    }),

    didRenderCaptcha: function didRenderCaptcha() {
      this._super.apply(this, arguments);

      var executeOnInitialRender = this.get('executeOnInitialRender');

      if (executeOnInitialRender) {
        this.execute();
      }
    },


    /**
     * Callback to the reset the component. Resetting the invisible reCAPTCHA will
     * automatically execute it again. This will force the widget to show the test
     * again, if necessary.
     */
    didReset: function didReset() {
      this._super.apply(this, arguments);
      this.execute();
    }
  });
});