define("ember-sort-filter-table/helpers/is-number", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isNumber = isNumber;
  function isNumber(params /*, hash*/) {
    var val = params[0];
    // See if this value only contains numbers (e.g., "12" but not "12 things")
    return val && /^[0-9]*$/.test(val);
  }

  exports.default = Ember.Helper.helper(isNumber);
});