define("ember-flatpickr/mixins/flatpickr", ["exports", "ember-diff-attrs"], function (_exports, _emberDiffAttrs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  /**
   * The mixin responsible for incorporating the [`flatpickr`](https://flatpickr.js.org)
   * features into an input/element.
   *
   * @class Flatpickr
   */
  var _default = Ember.Mixin.create({
    date: null,
    flatpickrRef: null,
    setupFlatpickr: function setupFlatpickr() {
      // Require that users pass a date
      (true && !(this.get("date") !== undefined) && Ember.assert("{{ember-flatpickr}} requires a `date` to be passed as the value for flatpickr.", this.get("date") !== undefined)); // Require that users pass an onChange

      (true && !(this.get("onChange") !== undefined) && Ember.assert("{{ember-flatpickr}} requires an `onChange` action or null for no action.", this.get("onChange") !== undefined)); // Wrap is not supported

      (true && !(this.attrs.wrap !== true) && Ember.assert("{{ember-flatpickr}} does not support the wrap option. Please see documentation for an alternative.", this.attrs.wrap !== true)); // Pass all values and setup flatpickr

      Ember.run.scheduleOnce("afterRender", this, function () {
        var fastboot = Ember.getOwner(this).lookup("service:fastboot");

        if (fastboot && fastboot.isFastBoot) {
          return;
        }

        var options = this.getProperties(Object.keys(this.attrs)); // Add defaultDate, change and close handlers

        Ember.assign(options, {
          inline: this.inline || options.inline,
          defaultDate: this.get("date"),
          onChange: this._onChange.bind(this),
          onClose: this._onClose.bind(this),
          onOpen: this._onOpen.bind(this),
          onReady: this._onReady.bind(this)
        });
        var flatpickrRef = flatpickr(this.field, options);

        if (this.get("appendDataInput")) {
          this.field.setAttribute("data-input", "");
        }

        this._setDisabled(this.get("disabled"));

        this.set("flatpickrRef", flatpickrRef);

        if (this.get("getFlatpickrRef")) {
          this.get("getFlatpickrRef")(flatpickrRef);
        }
      });
    },
    didReceiveAttrs: (0, _emberDiffAttrs.default)("altFormat", "date", "disabled", "locale", "maxDate", "minDate", function (changedAttrs) {
      var _this = this;

      for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      this._super.apply(this, args);

      this._attributeHasChanged(changedAttrs, "altFormat", function (newAltFormat) {
        _this.field._flatpickr.set("altFormat", newAltFormat);
      });

      this._attributeHasChanged(changedAttrs, "date", function (newDate) {
        if (typeof newDate !== "undefined") {
          _this.field._flatpickr.setDate(newDate);
        }
      });

      this._attributeHasChanged(changedAttrs, "disabled", function (newDisabled) {
        if (typeof newDisabled !== "undefined") {
          _this._setDisabled(newDisabled);
        }
      });

      this._attributeHasChanged(changedAttrs, "locale", function () {
        _this.field._flatpickr.destroy();

        _this.setupFlatpickr();
      });

      this._attributeHasChanged(changedAttrs, "maxDate", function (newMaxDate) {
        _this.field._flatpickr.set("maxDate", newMaxDate);
      });

      this._attributeHasChanged(changedAttrs, "minDate", function (newMinDate) {
        _this.field._flatpickr.set("minDate", newMinDate);
      });
    }),
    willDestroyElement: function willDestroyElement() {
      this.field._flatpickr.destroy();
    },
    _attributeHasChanged: function _attributeHasChanged(changedAttrs, attr, callback) {
      if (changedAttrs && changedAttrs[attr]) {
        var _changedAttrs$attr = _slicedToArray(changedAttrs[attr], 2),
            oldAttr = _changedAttrs$attr[0],
            newAttr = _changedAttrs$attr[1];

        if (oldAttr !== newAttr) {
          callback(newAttr);
        }
      }
    },

    /**
     * Triggered when the user selects a date, or changes the time on a selected date.
     *
     * @method onClose
     * @param {Array} selectedDates an array of Date objects selected by the user. When there are
     * no dates selected, the array is empty.
     * @param {String} dateStr a string representation of the latest selected Date object by the
     * user. The string is formatted as per the dateFormat option
     * @param {Object} instance the flatpickr object, containing various methods and properties.
     * @type {Action}
     * @return {void}
     */
    onChange: function onChange()
    /*selectedDates, dateStr, instance*/
    {},

    /**
     * Triggered when the calendar is closed.
     *
     * @method onClose
     * @param {Array} selectedDates an array of Date objects selected by the user. When there are
     * no dates selected, the array is empty.
     * @param {String} dateStr a string representation of the latest selected Date object by the
     * user. The string is formatted as per the dateFormat option
     * @param {Object} instance the flatpickr object, containing various methods and properties.
     * @type {Action}
     * @return {void}
     */
    onClose: function onClose()
    /*selectedDates, dateStr, instance*/
    {},

    /**
     * Triggered when the calendar is closed.
     *
     * @method onOpen
     * @param {Array} selectedDates an array of Date objects selected by the user. When there are
     * no dates selected, the array is empty.
     * @param {String} dateStr a string representation of the latest selected Date object by the
     * user. The string is formatted as per the dateFormat option
     * @param {Object} instance the flatpickr object, containing various methods and properties.
     * @type {Action}
     * @return {void}
     */
    onOpen: function onOpen()
    /*selectedDates, dateStr, instance*/
    {},

    /**
     * Triggered once the calendar is in a ready state.
     *
     * @method onReady
     * @param {Array} selectedDates an array of Date objects selected by the user. When there are
     * no dates selected, the array is empty.
     * @param {String} dateStr a string representation of the latest selected Date object by the
     * user. The string is formatted as per the dateFormat option
     * @param {Object} instance the flatpickr object, containing various methods and properties.
     * @type {Action}
     * @return {void}
     */
    onReady: function onReady()
    /*selectedDates, dateStr, instance*/
    {},
    _onChange: function _onChange(selectedDates, dateStr, instance) {
      if (this.onChange instanceof Function) {
        this.onChange(selectedDates, dateStr, instance);
      }
    },
    _onClose: function _onClose(selectedDates, dateStr, instance) {
      this.onClose(selectedDates, dateStr, instance);
    },
    _onOpen: function _onOpen(selectedDates, dateStr, instance) {
      this.onOpen(selectedDates, dateStr, instance);
    },
    _onReady: function _onReady(selectedDates, dateStr, instance) {
      this.onReady(selectedDates, dateStr, instance);
    },
    _setDisabled: function _setDisabled(disabled) {
      if (this.get("altInput")) {
        // `this.field` is the hidden input storing the alternate date value sent to the server
        // @see https://flatpickr.js.org/options/ `altInput` config options
        // Refactored during https://github.com/shipshapecode/ember-flatpickr/issues/306 to instead
        // extend Ember's `@ember/component/text-field`
        this.field.disabled = !disabled; // `this.field.nextSibling` is the text input that the user will interact with, so
        // long as it is enabled

        this.field.nextSibling.disabled = disabled;
      } else {
        this.field.disabled = disabled;
      }
    }
  });

  _exports.default = _default;
});