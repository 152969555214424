define("ember-sort-filter-table/helpers/is-image", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isImage = isImage;
  function isImage(params /*, hash*/) {
    var img = params[0];
    return img && /([^\s]+(\.(jpg|png|gif|bmp))$)/gm.test(img);
  }

  exports.default = Ember.Helper.helper(isImage);
});