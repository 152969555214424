define('ember-popper/components/ember-popper', ['exports', 'ember-popper/components/ember-popper-base', 'ember-decorators/object', 'ember-decorators/component'], function (exports, _emberPopperBase, _object, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = undefined;

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  function _possibleConstructorReturn(self, call) {
    if (!self) {
      throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    }

    return call && (typeof call === "object" || typeof call === "function") ? call : self;
  }

  var _get = function get(object, property, receiver) {
    if (object === null) object = Function.prototype;
    var desc = Object.getOwnPropertyDescriptor(object, property);

    if (desc === undefined) {
      var parent = Object.getPrototypeOf(object);

      if (parent === null) {
        return undefined;
      } else {
        return get(parent, property, receiver);
      }
    } else if ("value" in desc) {
      return desc.value;
    } else {
      var getter = desc.get;

      if (getter === undefined) {
        return undefined;
      }

      return getter.call(receiver);
    }
  };

  function _inherits(subClass, superClass) {
    if (typeof superClass !== "function" && superClass !== null) {
      throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    }

    subClass.prototype = Object.create(superClass && superClass.prototype, {
      constructor: {
        value: subClass,
        enumerable: false,
        writable: true,
        configurable: true
      }
    });
    if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
  }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _class, _desc, _value, _class2;

  var EmberPopper = (_dec = (0, _component.tagName)('div'), _dec2 = (0, _object.computed)(), _dec(_class = (_class2 = function (_EmberPopperBase) {
    _inherits(EmberPopper, _EmberPopperBase);

    function EmberPopper() {
      _classCallCheck(this, EmberPopper);

      return _possibleConstructorReturn(this, (EmberPopper.__proto__ || Object.getPrototypeOf(EmberPopper)).apply(this, arguments));
    }

    _createClass(EmberPopper, [{
      key: 'didInsertElement',
      value: function didInsertElement() {
        this._initialParentNode = this.element.parentNode;

        if (!true) {
          Ember.addObserver(this, 'eventsEnabled', this, this._updatePopper);
          Ember.addObserver(this, 'modifiers', this, this._updatePopper);
          Ember.addObserver(this, 'onCreate', this, this._updatePopper);
          Ember.addObserver(this, 'onUpdate', this, this._updatePopper);
          Ember.addObserver(this, 'placement', this, this._updatePopper);
          Ember.addObserver(this, 'popperContainer', this, this._updatePopper);
          Ember.addObserver(this, 'popperTarget', this, this._updatePopper);
          Ember.addObserver(this, 'registerAPI', this, this._updatePopper);
          Ember.addObserver(this, 'renderInPlace', this, this._updatePopper);

          _get(EmberPopper.prototype.__proto__ || Object.getPrototypeOf(EmberPopper.prototype), 'didRender', this).apply(this, arguments);
        }
      }
    }, {
      key: 'willDestroyElement',
      value: function willDestroyElement() {
        _get(EmberPopper.prototype.__proto__ || Object.getPrototypeOf(EmberPopper.prototype), 'willDestroyElement', this).apply(this, arguments);

        if (!true) {
          Ember.removeObserver(this, 'eventsEnabled', this, this._updatePopper);
          Ember.removeObserver(this, 'modifiers', this, this._updatePopper);
          Ember.removeObserver(this, 'onCreate', this, this._updatePopper);
          Ember.removeObserver(this, 'onUpdate', this, this._updatePopper);
          Ember.removeObserver(this, 'placement', this, this._updatePopper);
          Ember.removeObserver(this, 'popperContainer', this, this._updatePopper);
          Ember.removeObserver(this, 'popperTarget', this, this._updatePopper);
          Ember.removeObserver(this, 'registerAPI', this, this._updatePopper);
          Ember.removeObserver(this, 'renderInPlace', this, this._updatePopper);
        }

        var element = this._getPopperElement();

        if (element.parentNode !== this._initialParentNode) {
          element.parentNode.removeChild(element);
        }
      }
    }, {
      key: '_updatePopper',
      value: function _updatePopper() {
        var element = this._getPopperElement();
        var popperContainer = this.get('_popperContainer');
        var renderInPlace = this.get('_renderInPlace');

        // If renderInPlace is false, move the element to the popperContainer to avoid z-index issues.
        // See renderInPlace for more details.
        if (renderInPlace === false && element.parentNode !== popperContainer) {
          popperContainer.appendChild(element);
        }

        _get(EmberPopper.prototype.__proto__ || Object.getPrototypeOf(EmberPopper.prototype), '_updatePopper', this).call(this);
      }
    }, {
      key: '_getPopperElement',
      value: function _getPopperElement() {
        return this.element;
      }
    }, {
      key: '_popperHash',
      get: function get() {
        return {
          disableEventListeners: this.disableEventListeners.bind(this),
          enableEventListeners: this.enableEventListeners.bind(this),
          scheduleUpdate: this.scheduleUpdate.bind(this),
          update: this.update.bind(this)
        };
      }
    }]);

    return EmberPopper;
  }(_emberPopperBase.default), (_applyDecoratedDescriptor(_class2.prototype, '_popperHash', [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, '_popperHash'), _class2.prototype)), _class2)) || _class);
  exports.default = EmberPopper;
});