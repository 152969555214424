define("ember-bootstrap/templates/components/bs-carousel", ["exports"], function (exports) {
  "use strict";

  exports.__esModule = true;
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 3,
                "column": 4
              },
              "end": {
                "line": 5,
                "column": 4
              }
            },
            "moduleName": "ember-bootstrap/templates/components/bs-carousel.hbs"
          },
          isEmpty: false,
          arity: 2,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            dom.setAttribute(el1, "role", "button");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element4 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element4, 'class');
            morphs[1] = dom.createAttrMorph(element4, 'onclick');
            return morphs;
          },
          statements: [["attribute", "class", ["concat", [["subexpr", "if", [["subexpr", "bs-eq", [["get", "currentIndex", ["loc", [null, [4, 29], [4, 41]]], 0, 0, 0, 0], ["get", "_index", ["loc", [null, [4, 42], [4, 48]]], 0, 0, 0, 0]], [], ["loc", [null, [4, 22], [4, 49]]], 0, 0], "active"], [], ["loc", [null, [4, 17], [4, 60]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "onclick", ["subexpr", "action", ["toSlide", ["get", "_index", ["loc", [null, [4, 89], [4, 95]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [4, 97]]], 0, 0], 0, 0, 0, 0]],
          locals: ["indicator", "_index"],
          templates: []
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 7,
              "column": 0
            }
          },
          "moduleName": "ember-bootstrap/templates/components/bs-carousel.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("ol");
          dom.setAttribute(el1, "class", "carousel-indicators");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["block", "each", [["get", "indicators", ["loc", [null, [3, 12], [3, 22]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [3, 4], [5, 13]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    var child1 = function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 19,
              "column": 0
            },
            "end": {
              "line": 28,
              "column": 0
            }
          },
          "moduleName": "ember-bootstrap/templates/components/bs-carousel.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1, "role", "button");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2, "aria-hidden", "true");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2, "class", "sr-only");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1, "role", "button");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2, "aria-hidden", "true");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2, "class", "sr-only");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [1]);
          var element2 = dom.childAt(fragment, [3]);
          var element3 = dom.childAt(element2, [1]);
          var morphs = new Array(10);
          morphs[0] = dom.createAttrMorph(element0, 'class');
          morphs[1] = dom.createAttrMorph(element0, 'href');
          morphs[2] = dom.createElementMorph(element0);
          morphs[3] = dom.createAttrMorph(element1, 'class');
          morphs[4] = dom.createMorphAt(dom.childAt(element0, [3]), 0, 0);
          morphs[5] = dom.createAttrMorph(element2, 'class');
          morphs[6] = dom.createAttrMorph(element2, 'href');
          morphs[7] = dom.createElementMorph(element2);
          morphs[8] = dom.createAttrMorph(element3, 'class');
          morphs[9] = dom.createMorphAt(dom.childAt(element2, [3]), 0, 0);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", [["get", "prevControlClassName", ["loc", [null, [20, 14], [20, 34]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "href", ["concat", ["#", ["get", "elementId", ["loc", [null, [20, 47], [20, 56]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["toPrevSlide"], [], ["loc", [null, [20, 60], [20, 84]]], 0, 0], ["attribute", "class", ["concat", [["get", "prevControlIcon", ["loc", [null, [21, 38], [21, 53]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "prevControlLabel", ["loc", [null, [22, 26], [22, 46]]], 0, 0, 0, 0], ["attribute", "class", ["concat", [["get", "nextControlClassName", ["loc", [null, [24, 14], [24, 34]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "href", ["concat", ["#", ["get", "elementId", ["loc", [null, [24, 47], [24, 56]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["toNextSlide"], [], ["loc", [null, [24, 60], [24, 84]]], 0, 0], ["attribute", "class", ["concat", [["get", "nextControlIcon", ["loc", [null, [25, 38], [25, 53]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "nextControlLabel", ["loc", [null, [26, 26], [26, 46]]], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 29,
            "column": 0
          }
        },
        "moduleName": "ember-bootstrap/templates/components/bs-carousel.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "carousel-inner");
        dom.setAttribute(el1, "role", "listbox");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(dom.childAt(fragment, [2]), 1, 1);
        morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "showIndicators", ["loc", [null, [1, 6], [1, 20]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [1, 0], [7, 7]]]], ["inline", "yield", [["subexpr", "hash", [], ["slide", ["subexpr", "component", ["bs-carousel/slide"], ["currentSlide", ["subexpr", "@mut", [["get", "currentSlide", ["loc", [null, [11, 17], [11, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "directionalClassName", ["subexpr", "@mut", [["get", "directionalClassName", ["loc", [null, [12, 25], [12, 45]]], 0, 0, 0, 0]], [], [], 0, 0], "followingSlide", ["subexpr", "@mut", [["get", "followingSlide", ["loc", [null, [13, 19], [13, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "orderClassName", ["subexpr", "@mut", [["get", "orderClassName", ["loc", [null, [14, 19], [14, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "presentationState", ["subexpr", "@mut", [["get", "presentationState", ["loc", [null, [15, 22], [15, 39]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [10, 22], [16, 3]]], 0, 0]], ["loc", [null, [10, 10], [16, 4]]], 0, 0]], [], ["loc", [null, [10, 2], [16, 6]]], 0, 0], ["block", "if", [["get", "showControls", ["loc", [null, [19, 6], [19, 18]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [19, 0], [28, 7]]]]],
      locals: [],
      templates: [child0, child1]
    };
  }());
});