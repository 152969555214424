define('ember-cli-google-recaptcha/-private/g-recaptcha-base', ['exports', 'ember-cli-google-recaptcha/templates/components/g-recaptcha'], function (exports, _gRecaptcha) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _gRecaptcha.default,

    mergedProperties: ['_extendedOptions'],

    /// The Google reCAPTCHA service.
    grecaptcha: Ember.inject.service('g-recaptcha'),

    /// Set the required class names for the reCAPTCHA element.
    classNames: ['g-recaptcha'],

    /// The attribute bindings for the component.
    attributeBindings: ['tabIndex:data-tabindex'],

    theme: 'light',

    tabIndex: 0,

    /// Test if the recaptcha has a response.
    hasResponse: Ember.computed.bool('_response'),

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      var _getProperties = this.getProperties(['size', 'type', 'theme', 'tabIndex', 'grecaptcha', '_callback', '_expiredCallback', '_extendedOptions']),
          size = _getProperties.size,
          type = _getProperties.type,
          theme = _getProperties.theme,
          tabIndex = _getProperties.tabIndex,
          grecaptcha = _getProperties.grecaptcha,
          _callback = _getProperties._callback,
          _expiredCallback = _getProperties._expiredCallback,
          _extendedOptions = _getProperties._extendedOptions;

      var options = Ember.merge({
        size: size,
        type: type,
        theme: theme,
        tabindex: tabIndex,
        callback: _callback.bind(this),
        'expired-callback': _expiredCallback.bind(this)
      }, _extendedOptions);

      grecaptcha.render(this.elementId, options).then(function (widgetId) {
        _this.set('widgetId', widgetId);
        _this.didRenderCaptcha();
      });
    },
    didRenderCaptcha: function didRenderCaptcha() {},
    didRender: function didRender() {
      this._super.apply(this, arguments);

      // Handle reset the recaptcha.
      var reset = this.get('reset');

      if (reset) this.resetCaptcha();
    },
    execute: function execute() {
      var _getProperties2 = this.getProperties(['grecaptcha', 'widgetId']),
          grecaptcha = _getProperties2.grecaptcha,
          widgetId = _getProperties2.widgetId;

      grecaptcha.execute(widgetId);
    },


    /**
     * Reset the reCATPCHA component.
     */
    resetCaptcha: function resetCaptcha() {
      var _this2 = this;

      var _getProperties3 = this.getProperties(['grecaptcha', 'widgetId']),
          grecaptcha = _getProperties3.grecaptcha,
          widgetId = _getProperties3.widgetId;

      grecaptcha.reset(widgetId).then(function () {
        _this2.setProperties({ reset: false, _response: null });
        _this2.didReset();
      });
    },


    /**
     * Callback that the widget has been reset.
     */
    didReset: function didReset() {},


    /**
     * The name of your callback function to be executed when the user submits
     * a successful CAPTCHA response. The user's response, g-recaptcha-response,
     * will be the input for your callback function.
     *
     * @private
     */
    _callback: function _callback() {
      var _this3 = this;

      var _getProperties4 = this.getProperties(['grecaptcha', 'widgetId']),
          grecaptcha = _getProperties4.grecaptcha,
          widgetId = _getProperties4.widgetId;

      grecaptcha.getResponse(widgetId).then(function (response) {
        // Store the response for the reCAPTCHA widget. This will allow us to
        // access it at a later time.
        _this3.set('_response', response);
      });
    },


    _responseChanged: Ember.observer('_response', function () {
      // Let the client (or parent) know that we have received a response. We,
      // however, are not going to tell them the response value since that is not
      // really important to them.
      var response = this.get('_response');
      this.sendAction('verified', response);
    }),

    /**
     * Callback function to be executed when the recaptcha response expires and the
     * user needs to solve a new CAPTCHA.
     *
     * @private
     */
    _expiredCallback: function _expiredCallback() {
      this.sendAction('expired');
    }
  });
});