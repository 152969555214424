define('ember-rapid-forms/components/em-input', ['exports', 'ember', 'ember-rapid-forms/components/em-form-group'], function (exports, _ember, _emberRapidFormsComponentsEmFormGroup) {

  /*
  Form Input
  
  Syntax:
  {{em-input property="property name"}}
   */
  exports['default'] = _emberRapidFormsComponentsEmFormGroup['default'].extend({
    elementClass: null,
    htmlComponent: 'erf-html-input',
    property: null,
    label: null,
    name: null,
    placeholder: null,
    required: null,
    title: null,
    pattern: null,
    autofocus: null,
    readonly: null,
    autoresize: null,
    disabled: null,
    controlWrapper: _ember['default'].computed('form.formLayout', {
      get: function get() {
        if (this.get('form.formLayout') === 'horizontal') {
          return 'col-sm-10';
        }
        return null;
      }
    })
  });
});