define('ember-rapid-forms/mixins/has-property-validation', ['exports', 'ember'], function (exports, _ember) {

  /*
  A mixin that enriches a component that is attached to a model property that has validation
      support.
  
  This mixin binds a property named `errors` to the model's `model.errors.@propertyName` array
   */

  exports['default'] = _ember['default'].Mixin.create({
    init: function init() {
      this._super.apply(this, arguments);
      _ember['default'].assert(!_ember['default'].isNone(this.get('propertyName')), 'propertyName is required.');
      _ember['default'].defineProperty(this, 'errors', _ember['default'].computed.alias('model.errors.' + this.get('propertyName')));
    },
    status: _ember['default'].computed('errors.length', 'form.isSubmitted', {
      get: function get() {
        if (this.get('errors.length')) {
          if (this.get('form.showErrorsOnRender')) {
            this.set('canShowErrors', true);
          }
          if (this.get('form.showErrorsOnSubmit') && this.get('form.isSubmitted')) {
            this.set('canShowErrors', true);
          }
          return 'error';
        } else {
          return 'success';
        }
      }
    })
  });
});