define('ember-rapid-forms/mixins/has-property', ['exports', 'ember'], function (exports, _ember) {

  /*
  A mixin that enriches a component that is attached to a model property.
  
  The property name by default is taken from the mainComponent unless explictly
      defined in the `property` variable.
  
  This mixin also binds a property named `errors` to the model's `model.errors.@propertyName` array
   */

  exports['default'] = _ember['default'].Mixin.create({
    property: undefined,
    propertyName: _ember['default'].computed('property', 'mainComponent.property', {
      get: function get() {
        if (this.get('property')) {
          return this.get('property');
        } else if (this.get('mainComponent.property')) {
          return this.get('mainComponent.property');
        } else {
          return _ember['default'].assert(false, 'Property could not be found.');
        }
      }
    }),
    id: _ember['default'].computed('cid', 'property', {
      get: function get() {
        if (this.get('cid')) {
          return this.get('cid');
        } else {
          return this.get('property') + '-' + this.elementId;
        }
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);
      _ember['default'].defineProperty(this, 'errors', _ember['default'].computed.alias('model.errors.' + this.get('propertyName')));
    }
  });
});