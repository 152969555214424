define("ember-sort-filter-table/helpers/is-private-key", ["exports", "ember-sort-filter-table/utils"], function (exports, _utils) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isPrivateKey = isPrivateKey;


  /**
    Object keys with a leading underscore should be designated as private
  
    @helper isPrivateKey
    @private
    @returns Bool
  */
  function isPrivateKey(params /*, hash*/) {
    var key = params[0];
    return key ? (0, _utils.isPrivateKey)(key) : false;
  }

  exports.default = Ember.Helper.helper(isPrivateKey);
});