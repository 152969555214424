define("ember-bootstrap/templates/components/bs-tooltip/element", ["exports"], function (exports) {
  "use strict";

  exports.__esModule = true;
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 17,
              "column": 0
            }
          },
          "moduleName": "ember-bootstrap/templates/components/bs-tooltip/element.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "tooltip-inner");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element0, 'class');
          morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3]), 1, 1);
          return morphs;
        },
        statements: [["attribute", "class", ["get", "arrowClass", ["loc", [null, [13, 15], [13, 25]]], 0, 0, 0, 0], 0, 0, 0, 0], ["content", "yield", ["loc", [null, [15, 4], [15, 13]]], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 17,
            "column": 17
          }
        },
        "moduleName": "ember-bootstrap/templates/components/bs-tooltip/element.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "ember-popper", [], ["id", ["subexpr", "@mut", [["get", "id", ["loc", [null, [2, 5], [2, 7]]], 0, 0, 0, 0]], [], [], 0, 0], "class", ["subexpr", "@mut", [["get", "popperClass", ["loc", [null, [3, 8], [3, 19]]], 0, 0, 0, 0]], [], [], 0, 0], "ariaRole", ["subexpr", "@mut", [["get", "ariaRole", ["loc", [null, [4, 11], [4, 19]]], 0, 0, 0, 0]], [], [], 0, 0], "placement", ["subexpr", "@mut", [["get", "placement", ["loc", [null, [5, 12], [5, 21]]], 0, 0, 0, 0]], [], [], 0, 0], "renderInPlace", ["subexpr", "@mut", [["get", "renderInPlace", ["loc", [null, [6, 16], [6, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "popperTarget", ["subexpr", "@mut", [["get", "popperTarget", ["loc", [null, [7, 15], [7, 27]]], 0, 0, 0, 0]], [], [], 0, 0], "modifiers", ["subexpr", "@mut", [["get", "popperModifiers", ["loc", [null, [8, 12], [8, 27]]], 0, 0, 0, 0]], [], [], 0, 0], "popperContainer", "#ember-bootstrap-wormhole", "onCreate", ["subexpr", "action", ["updatePlacement"], [], ["loc", [null, [10, 11], [10, 37]]], 0, 0], "onUpdate", ["subexpr", "action", ["updatePlacement"], [], ["loc", [null, [11, 11], [11, 37]]], 0, 0]], 0, null, ["loc", [null, [1, 0], [17, 17]]]]],
      locals: [],
      templates: [child0]
    };
  }());
});