define("ember-bootstrap/templates/components/bs-modal-simple", ["exports"], function (exports) {
  "use strict";

  exports.__esModule = true;
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        var child0 = function () {
          var child0 = function () {
            return {
              meta: {
                "revision": "Ember@2.7.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 18,
                    "column": 6
                  },
                  "end": {
                    "line": 25,
                    "column": 6
                  }
                },
                "moduleName": "ember-bootstrap/templates/components/bs-modal-simple.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "yield", [["subexpr", "hash", [], ["close", ["subexpr", "action", ["close"], [], ["loc", [null, [21, 18], [21, 34]]], 0, 0], "submit", ["subexpr", "action", ["submit"], [], ["loc", [null, [22, 19], [22, 36]]], 0, 0]], ["loc", [null, [20, 10], [23, 11]]], 0, 0]], [], ["loc", [null, [19, 8], [24, 10]]], 0, 0]],
              locals: [],
              templates: []
            };
          }();
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 3,
                  "column": 4
                },
                "end": {
                  "line": 33,
                  "column": 4
                }
              },
              "moduleName": "ember-bootstrap/templates/components/bs-modal-simple.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
              morphs[2] = dom.createMorphAt(fragment, 5, 5, contextualElement);
              return morphs;
            },
            statements: [["inline", "bs-modal/header", [], ["title", ["subexpr", "@mut", [["get", "title", ["loc", [null, [17, 30], [17, 35]]], 0, 0, 0, 0]], [], [], 0, 0], "closeButton", ["subexpr", "@mut", [["get", "closeButton", ["loc", [null, [17, 48], [17, 59]]], 0, 0, 0, 0]], [], [], 0, 0], "onClose", ["subexpr", "action", ["close"], [], ["loc", [null, [17, 68], [17, 84]]], 0, 0]], ["loc", [null, [17, 6], [17, 86]]], 0, 0], ["block", "bs-modal/body", [], [], 0, null, ["loc", [null, [18, 6], [25, 24]]]], ["inline", "bs-modal/footer", [], ["closeTitle", ["subexpr", "@mut", [["get", "closeTitle", ["loc", [null, [27, 19], [27, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "submitTitle", ["subexpr", "@mut", [["get", "submitTitle", ["loc", [null, [28, 20], [28, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "submitButtonType", ["subexpr", "@mut", [["get", "submitButtonType", ["loc", [null, [29, 25], [29, 41]]], 0, 0, 0, 0]], [], [], 0, 0], "onClose", ["subexpr", "action", ["close"], [], ["loc", [null, [30, 16], [30, 32]]], 0, 0], "onSubmit", ["subexpr", "action", ["submit"], [], ["loc", [null, [31, 17], [31, 34]]], 0, 0]], ["loc", [null, [26, 6], [32, 8]]], 0, 0]],
            locals: [],
            templates: [child0]
          };
        }();
        var child1 = function () {
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 36,
                  "column": 6
                },
                "end": {
                  "line": 38,
                  "column": 6
                }
              },
              "moduleName": "ember-bootstrap/templates/components/bs-modal-simple.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createAttrMorph(element1, 'class');
              morphs[1] = dom.createAttrMorph(element1, 'id');
              return morphs;
            },
            statements: [["attribute", "class", ["concat", ["modal-backdrop ", ["subexpr", "if", [["get", "fade", ["loc", [null, [37, 40], [37, 44]]], 0, 0, 0, 0], "fade"], [], ["loc", [null, [37, 35], [37, 53]]], 0, 0], " ", ["subexpr", "if", [["get", "showModal", ["loc", [null, [37, 59], [37, 68]]], 0, 0, 0, 0], ["get", "showClass", ["loc", [null, [37, 69], [37, 78]]], 0, 0, 0, 0]], [], ["loc", [null, [37, 54], [37, 80]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "id", ["concat", [["get", "backdropId", ["loc", [null, [37, 88], [37, 98]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 2,
                "column": 2
              },
              "end": {
                "line": 40,
                "column": 2
              }
            },
            "moduleName": "ember-bootstrap/templates/components/bs-modal-simple.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            morphs[1] = dom.createMorphAt(dom.childAt(fragment, [2]), 1, 1);
            dom.insertBoundary(fragment, 0);
            return morphs;
          },
          statements: [["block", "bs-modal/dialog", [], ["onClose", ["subexpr", "action", ["close"], [], ["loc", [null, [4, 14], [4, 30]]], 0, 0], "fade", ["subexpr", "@mut", [["get", "fade", ["loc", [null, [5, 11], [5, 15]]], 0, 0, 0, 0]], [], [], 0, 0], "showModal", ["subexpr", "@mut", [["get", "showModal", ["loc", [null, [6, 16], [6, 25]]], 0, 0, 0, 0]], [], [], 0, 0], "id", ["subexpr", "@mut", [["get", "modalId", ["loc", [null, [7, 9], [7, 16]]], 0, 0, 0, 0]], [], [], 0, 0], "keyboard", ["subexpr", "@mut", [["get", "keyboard", ["loc", [null, [8, 15], [8, 23]]], 0, 0, 0, 0]], [], [], 0, 0], "size", ["subexpr", "@mut", [["get", "size", ["loc", [null, [9, 11], [9, 15]]], 0, 0, 0, 0]], [], [], 0, 0], "backdropClose", ["subexpr", "@mut", [["get", "backdropClose", ["loc", [null, [10, 20], [10, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "class", ["subexpr", "@mut", [["get", "class", ["loc", [null, [11, 12], [11, 17]]], 0, 0, 0, 0]], [], [], 0, 0], "inDom", ["subexpr", "@mut", [["get", "inDom", ["loc", [null, [12, 12], [12, 17]]], 0, 0, 0, 0]], [], [], 0, 0], "paddingLeft", ["subexpr", "@mut", [["get", "paddingLeft", ["loc", [null, [13, 18], [13, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "paddingRight", ["subexpr", "@mut", [["get", "paddingRight", ["loc", [null, [14, 19], [14, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "centered", ["subexpr", "bs-eq", [["get", "position", ["loc", [null, [15, 22], [15, 30]]], 0, 0, 0, 0], "center"], [], ["loc", [null, [15, 15], [15, 40]]], 0, 0]], 0, null, ["loc", [null, [3, 4], [33, 24]]]], ["block", "if", [["get", "showBackdrop", ["loc", [null, [36, 12], [36, 24]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [36, 6], [38, 13]]]]],
          locals: [],
          templates: [child0, child1]
        };
      }();
      var child1 = function () {
        var child0 = function () {
          var child0 = function () {
            var child0 = function () {
              return {
                meta: {
                  "revision": "Ember@2.7.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 18,
                      "column": 6
                    },
                    "end": {
                      "line": 25,
                      "column": 6
                    }
                  },
                  "moduleName": "ember-bootstrap/templates/components/bs-modal-simple.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("        ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["inline", "yield", [["subexpr", "hash", [], ["close", ["subexpr", "action", ["close"], [], ["loc", [null, [21, 18], [21, 34]]], 0, 0], "submit", ["subexpr", "action", ["submit"], [], ["loc", [null, [22, 19], [22, 36]]], 0, 0]], ["loc", [null, [20, 10], [23, 11]]], 0, 0]], [], ["loc", [null, [19, 8], [24, 10]]], 0, 0]],
                locals: [],
                templates: []
              };
            }();
            return {
              meta: {
                "revision": "Ember@2.7.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 3,
                    "column": 4
                  },
                  "end": {
                    "line": 33,
                    "column": 4
                  }
                },
                "moduleName": "ember-bootstrap/templates/components/bs-modal-simple.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("      ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("      ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(3);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
                morphs[2] = dom.createMorphAt(fragment, 5, 5, contextualElement);
                return morphs;
              },
              statements: [["inline", "bs-modal/header", [], ["title", ["subexpr", "@mut", [["get", "title", ["loc", [null, [17, 30], [17, 35]]], 0, 0, 0, 0]], [], [], 0, 0], "closeButton", ["subexpr", "@mut", [["get", "closeButton", ["loc", [null, [17, 48], [17, 59]]], 0, 0, 0, 0]], [], [], 0, 0], "onClose", ["subexpr", "action", ["close"], [], ["loc", [null, [17, 68], [17, 84]]], 0, 0]], ["loc", [null, [17, 6], [17, 86]]], 0, 0], ["block", "bs-modal/body", [], [], 0, null, ["loc", [null, [18, 6], [25, 24]]]], ["inline", "bs-modal/footer", [], ["closeTitle", ["subexpr", "@mut", [["get", "closeTitle", ["loc", [null, [27, 19], [27, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "submitTitle", ["subexpr", "@mut", [["get", "submitTitle", ["loc", [null, [28, 20], [28, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "submitButtonType", ["subexpr", "@mut", [["get", "submitButtonType", ["loc", [null, [29, 25], [29, 41]]], 0, 0, 0, 0]], [], [], 0, 0], "onClose", ["subexpr", "action", ["close"], [], ["loc", [null, [30, 16], [30, 32]]], 0, 0], "onSubmit", ["subexpr", "action", ["submit"], [], ["loc", [null, [31, 17], [31, 34]]], 0, 0]], ["loc", [null, [26, 6], [32, 8]]], 0, 0]],
              locals: [],
              templates: [child0]
            };
          }();
          var child1 = function () {
            return {
              meta: {
                "revision": "Ember@2.7.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 36,
                    "column": 6
                  },
                  "end": {
                    "line": 38,
                    "column": 6
                  }
                },
                "moduleName": "ember-bootstrap/templates/components/bs-modal-simple.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element0 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createAttrMorph(element0, 'class');
                morphs[1] = dom.createAttrMorph(element0, 'id');
                return morphs;
              },
              statements: [["attribute", "class", ["concat", ["modal-backdrop ", ["subexpr", "if", [["get", "fade", ["loc", [null, [37, 40], [37, 44]]], 0, 0, 0, 0], "fade"], [], ["loc", [null, [37, 35], [37, 53]]], 0, 0], " ", ["subexpr", "if", [["get", "showModal", ["loc", [null, [37, 59], [37, 68]]], 0, 0, 0, 0], ["get", "showClass", ["loc", [null, [37, 69], [37, 78]]], 0, 0, 0, 0]], [], ["loc", [null, [37, 54], [37, 80]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "id", ["concat", [["get", "backdropId", ["loc", [null, [37, 88], [37, 98]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0]],
              locals: [],
              templates: []
            };
          }();
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 2,
                  "column": 2
                },
                "end": {
                  "line": 40,
                  "column": 2
                }
              },
              "moduleName": "ember-bootstrap/templates/components/bs-modal-simple.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("    ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              morphs[1] = dom.createMorphAt(dom.childAt(fragment, [2]), 1, 1);
              dom.insertBoundary(fragment, 0);
              return morphs;
            },
            statements: [["block", "bs-modal/dialog", [], ["onClose", ["subexpr", "action", ["close"], [], ["loc", [null, [4, 14], [4, 30]]], 0, 0], "fade", ["subexpr", "@mut", [["get", "fade", ["loc", [null, [5, 11], [5, 15]]], 0, 0, 0, 0]], [], [], 0, 0], "showModal", ["subexpr", "@mut", [["get", "showModal", ["loc", [null, [6, 16], [6, 25]]], 0, 0, 0, 0]], [], [], 0, 0], "id", ["subexpr", "@mut", [["get", "modalId", ["loc", [null, [7, 9], [7, 16]]], 0, 0, 0, 0]], [], [], 0, 0], "keyboard", ["subexpr", "@mut", [["get", "keyboard", ["loc", [null, [8, 15], [8, 23]]], 0, 0, 0, 0]], [], [], 0, 0], "size", ["subexpr", "@mut", [["get", "size", ["loc", [null, [9, 11], [9, 15]]], 0, 0, 0, 0]], [], [], 0, 0], "backdropClose", ["subexpr", "@mut", [["get", "backdropClose", ["loc", [null, [10, 20], [10, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "class", ["subexpr", "@mut", [["get", "class", ["loc", [null, [11, 12], [11, 17]]], 0, 0, 0, 0]], [], [], 0, 0], "inDom", ["subexpr", "@mut", [["get", "inDom", ["loc", [null, [12, 12], [12, 17]]], 0, 0, 0, 0]], [], [], 0, 0], "paddingLeft", ["subexpr", "@mut", [["get", "paddingLeft", ["loc", [null, [13, 18], [13, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "paddingRight", ["subexpr", "@mut", [["get", "paddingRight", ["loc", [null, [14, 19], [14, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "centered", ["subexpr", "bs-eq", [["get", "position", ["loc", [null, [15, 22], [15, 30]]], 0, 0, 0, 0], "center"], [], ["loc", [null, [15, 15], [15, 40]]], 0, 0]], 0, null, ["loc", [null, [3, 4], [33, 24]]]], ["block", "if", [["get", "showBackdrop", ["loc", [null, [36, 12], [36, 24]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [36, 6], [38, 13]]]]],
            locals: [],
            templates: [child0, child1]
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": null,
            "moduleName": "ember-bootstrap/templates/components/bs-modal-simple.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "ember-wormhole", [], ["destinationElement", ["subexpr", "@mut", [["get", "destinationElement", ["loc", [null, [2, 22], [2, 40]]], 0, 0, 0, 0]], [], [], 0, 0]], 0, null, []]],
          locals: [],
          templates: [child0]
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 41,
              "column": 0
            }
          },
          "moduleName": "ember-bootstrap/templates/components/bs-modal-simple.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "_renderInPlace", ["loc", [null, [2, 41], [2, 55]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [2, 2], [40, 23]]]]],
        locals: [],
        templates: [child0, child1]
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 41,
            "column": 7
          }
        },
        "moduleName": "ember-bootstrap/templates/components/bs-modal-simple.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "inDom", ["loc", [null, [1, 6], [1, 11]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [1, 0], [41, 7]]]]],
      locals: [],
      templates: [child0]
    };
  }());
});