define('ember-sort-filter-table/components/sf-headings', ['exports', 'ember-sort-filter-table/templates/components/sf-headings'], function (exports, _sfHeadings) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _sfHeadings.default,
    tagName: '',
    init: function init() {
      this._super.apply(this, arguments);
      this._syncWithGroup();
    },


    actions: {
      /**
        * Updates sort key and direction
        *
        * @method sort
        * @param key {String}
        * @public
        */
      sort: function sort(key) {
        // Reference current direction
        var dir = Ember.get(this, 'group.groupSortDirection');
        // Toggle direction
        Ember.set(this, 'group.groupSortDirection', dir === 'asc' ? 'desc' : 'asc');
        // Update sort key
        Ember.set(this, 'group.groupSortKey', key);
      }
    },

    /**
      Provide headings to group
       @method _syncWithGroup
      @private
    */
    _syncWithGroup: function _syncWithGroup() {
      if (Ember.get(this, 'headings')) {
        Ember.set(this, 'group.groupHeadings', Ember.get(this, 'headings').getEach('key'));
      }
    }
  });
});