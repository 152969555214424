define("ember-bootstrap/templates/components/bs-popover", ["exports"], function (exports) {
  "use strict";

  exports.__esModule = true;
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 2,
                "column": 2
              },
              "end": {
                "line": 17,
                "column": 2
              }
            },
            "moduleName": "ember-bootstrap/templates/components/bs-popover.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["content", "yield", ["loc", [null, [16, 4], [16, 13]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 18,
              "column": 0
            }
          },
          "moduleName": "ember-bootstrap/templates/components/bs-popover.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "bs-popover/element", [], ["id", ["subexpr", "@mut", [["get", "overlayId", ["loc", [null, [3, 7], [3, 16]]], 0, 0, 0, 0]], [], [], 0, 0], "parent", ["subexpr", "@mut", [["get", "this", ["loc", [null, [4, 11], [4, 15]]], 0, 0, 0, 0]], [], [], 0, 0], "placement", ["subexpr", "@mut", [["get", "placement", ["loc", [null, [5, 14], [5, 23]]], 0, 0, 0, 0]], [], [], 0, 0], "fade", ["subexpr", "@mut", [["get", "fade", ["loc", [null, [6, 9], [6, 13]]], 0, 0, 0, 0]], [], [], 0, 0], "showHelp", ["subexpr", "@mut", [["get", "showHelp", ["loc", [null, [7, 13], [7, 21]]], 0, 0, 0, 0]], [], [], 0, 0], "title", ["subexpr", "@mut", [["get", "title", ["loc", [null, [8, 10], [8, 15]]], 0, 0, 0, 0]], [], [], 0, 0], "class", ["subexpr", "@mut", [["get", "class", ["loc", [null, [9, 10], [9, 15]]], 0, 0, 0, 0]], [], [], 0, 0], "renderInPlace", ["subexpr", "@mut", [["get", "_renderInPlace", ["loc", [null, [10, 18], [10, 32]]], 0, 0, 0, 0]], [], [], 0, 0], "popperTarget", ["subexpr", "@mut", [["get", "triggerTargetElement", ["loc", [null, [11, 17], [11, 37]]], 0, 0, 0, 0]], [], [], 0, 0], "autoPlacement", ["subexpr", "@mut", [["get", "autoPlacement", ["loc", [null, [12, 18], [12, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "viewportElement", ["subexpr", "@mut", [["get", "viewportElement", ["loc", [null, [13, 20], [13, 35]]], 0, 0, 0, 0]], [], [], 0, 0], "viewportPadding", ["subexpr", "@mut", [["get", "viewportPadding", ["loc", [null, [14, 20], [14, 35]]], 0, 0, 0, 0]], [], [], 0, 0]], 0, null, ["loc", [null, [2, 2], [17, 25]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 19,
            "column": 0
          }
        },
        "moduleName": "ember-bootstrap/templates/components/bs-popover.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "inDom", ["loc", [null, [1, 6], [1, 11]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [1, 0], [18, 7]]]]],
      locals: [],
      templates: [child0]
    };
  }());
});