define("ember-rapid-forms/templates/components/html-text", ["exports"], function (exports) {
  "use strict";

  exports.__esModule = true;
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 15,
            "column": 0
          }
        },
        "moduleName": "ember-rapid-forms/templates/components/html-text.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "textarea", [], ["rows", ["subexpr", "@mut", [["get", "mainComponent.rows", ["loc", [null, [2, 7], [2, 25]]], 0, 0, 0, 0]], [], [], 0, 0], "cols", ["subexpr", "@mut", [["get", "mainComponent.cols", ["loc", [null, [3, 7], [3, 25]]], 0, 0, 0, 0]], [], [], 0, 0], "placeholder", ["subexpr", "@mut", [["get", "mainComponent.placeholder", ["loc", [null, [4, 14], [4, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "value", ["subexpr", "@mut", [["get", "selectedValue", ["loc", [null, [5, 8], [5, 21]]], 0, 0, 0, 0]], [], [], 0, 0], "name", ["subexpr", "@mut", [["get", "mainComponent.name", ["loc", [null, [6, 7], [6, 25]]], 0, 0, 0, 0]], [], [], 0, 0], "disabled", ["subexpr", "@mut", [["get", "mainComponent.disabled", ["loc", [null, [7, 11], [7, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "class", ["subexpr", "concat", ["form-control ", ["get", "mainComponent.elementClass", ["loc", [null, [8, 32], [8, 58]]], 0, 0, 0, 0]], [], ["loc", [null, [8, 8], [8, 59]]], 0, 0], "id", ["subexpr", "@mut", [["get", "mainComponent.id", ["loc", [null, [9, 5], [9, 21]]], 0, 0, 0, 0]], [], [], 0, 0], "required", ["subexpr", "@mut", [["get", "mainComponent.required", ["loc", [null, [10, 11], [10, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "autofocus", ["subexpr", "@mut", [["get", "mainComponent.autofocus", ["loc", [null, [11, 12], [11, 35]]], 0, 0, 0, 0]], [], [], 0, 0], "readonly", ["subexpr", "@mut", [["get", "mainComponent.readonly", ["loc", [null, [12, 11], [12, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "autoresize", ["subexpr", "@mut", [["get", "mainComponent.autoresize", ["loc", [null, [13, 13], [13, 37]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [1, 0], [14, 2]]], 0, 0]],
      locals: [],
      templates: []
    };
  }());
});