define('ember-sort-filter-table/components/sf-em-data', ['exports', 'ember-sort-filter-table/templates/components/sf-em-data', 'ember-decorators/object'], function (exports, _sfEmData, _object) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _desc, _value, _obj2;

  var keys = Object.keys;
  exports.default = Ember.Component.extend((_dec = (0, _object.computed)('store'), (_obj2 = {
    layout: _sfEmData.default,
    tagName: '',

    /**
      Signal if nested arrays or objects should be displayed as a list
       @property shouldListNested
      @default true
      @type {Bool}
      @public
    */
    shouldListNested: true,

    /**
      Extract plain object from ember data internal model
       @method _extractPojo
      @param store {Service}
      @private
    */
    _extractPojo: function _extractPojo(store) {
      return store.toArray().map(function (item) {
        return Ember.get(item, '_internalModel.__data');
      });
    },


    /**
      Filter out nodes when a corresponding header was not provided
      headings ['name', 'address']
      data [{ name, address, phone }, {...]
      result [ {name, address }, {... ]
       @method _filterByHeadings
      @param arr {Array}
      @param headings {Array}
      @returns {Array}
      @private
    */
    _filterByHeadings: function _filterByHeadings(arr, headings) {
      return arr.map(function (obj) {
        // prevent any mutation on the original object
        var _obj = Ember.assign({}, obj);
        // when headings and keys match, return early
        if (headings.join() === keys(_obj).join()) {
          return _obj;
        }
        // otherwise, remove nodes where headings array does not include key
        for (var key in _obj) {
          if (!headings.includes(key)) {
            delete _obj[key];
          }
        }
        return _obj;
      });
    },

    /**
      Re-arranges records in the same order the headings suggest
      headings suggest ...["foo", "baz"]
      re-arrange records from... [{baz:"value", foo:"value"}] to [{foo:"value", baz:"value"}]
       @method _arrangeByHeadings
      @param arr {Array} POJA of ember data records
      @param headings {Array} List of headings
      @returns {Array}
      @private
    */
    _arrangeByHeadings: function _arrangeByHeadings(arr, headings) {
      return arr.map(function (obj) {
        return headings.reduce(function (hash, heading) {
          hash[heading] = obj[heading];
          return hash;
        }, {});
      });
    },
    poja: function poja(store) {
      var objArr = [];
      var headings = Ember.get(this, 'group.groupHeadings');

      if (store) {
        objArr = this._extractPojo(store);
        if (headings && headings.length) {
          objArr = this._filterByHeadings(objArr, headings);
          objArr = this._arrangeByHeadings(objArr, headings);
        }
      }
      return objArr.map(function (item) {
        return Ember.assign({}, item);
      });
    }
  }, (_applyDecoratedDescriptor(_obj2, 'poja', [_dec], Object.getOwnPropertyDescriptor(_obj2, 'poja'), _obj2)), _obj2)));
});