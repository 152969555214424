define('ember-rapid-forms/mixins/input-errors', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    init: function init() {
      this._super();
      this.set('visibleErrors', {});
    },
    showErrors: function showErrors() {
      this.changeErrorsVisibility(true);
    },
    hideErrors: function hideErrors() {
      this.changeErrorsVisibility(false);
    },
    changeErrorsVisibility: function changeErrorsVisibility(visible) {
      var _this = this;

      this.eachAttribute(function (key) {
        _this.set('visibleErrors.' + key, visible);
      });
      this.eachRelationship(function (key) {
        _this.set('visibleErrors.' + key, visible);
      });
      Object.keys(this.get('dependentValidationKeys')).forEach(function (key) {
        if (key.indexOf('.') === -1) {
          _this.set('visibleErrors.' + key, visible);
        }
      });
    }
  });
});