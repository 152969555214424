define('ember-changeset-validations-template-helpers/components/validated-input', ['exports', 'ember', 'ember-changeset-validations-template-helpers/templates/components/validated-input', 'ember-get-config'], function (exports, _ember, _emberChangesetValidationsTemplateHelpersTemplatesComponentsValidatedInput, _emberGetConfig) {
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var and = computed.and;
  exports['default'] = Component.extend({
    layout: _emberChangesetValidationsTemplateHelpersTemplatesComponentsValidatedInput['default'],
    classNames: ["validated-input-group"],
    classNameBindings: ["isUsingBootstrap:form-group", "isInvalid:validated-input-group-error"],
    "auto-focus": false,
    "property-name": _ember['default'].computed.alias("propertyName"),
    isInvalid: and("isValidationFailing", "isBlurred"),
    isValidationFailing: computed("changeset.error", function () {
      var changesetError = this.get("changeset.error");
      var propertyName = this.get("property-name");

      if (changesetError === undefined) {
        return false;
      }

      var errorKeys = Object.keys(changesetError);

      if (errorKeys === undefined) {
        return false;
      }
      return errorKeys.indexOf(propertyName) != -1;
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var autoFocus = get(this, "auto-focus");
      if (autoFocus) {
        _ember['default'].run.scheduleOnce('afterRender', this, '_autofocus');
      }
    },
    _autofocus: function _autofocus() {
      this.$("input:first").focus();
    },
    "input-classes": computed("isUsingBootstrap", "input-class", function () {
      var properties = this.getProperties("isUsingBootstrap", "input-class");
      var result = [];
      result.push(properties["input-class"]);
      if (properties.isUsingBootstrap) {
        result.push("form-control");
      }
      return result.join(" ");
    }),
    isUsingBootstrap: computed(function () {
      var settings = _emberGetConfig['default']["validations-template-helpers"];
      if (settings === undefined || settings["is-using-bootstrap"] === undefined) {
        return false;
      }
      var setting = _emberGetConfig['default']["validations-template-helpers"]["is-using-bootstrap"];
      return setting;
    }),
    actions: {
      blurred: function blurred() {
        var _this = this;

        this.get('changeset').validate().then(function () {
          set(_this, "isBlurred", true);
        });
      }
    }

  });
});