define('ember-cli-google-recaptcha/services/g-recaptcha', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    init: function init() {
      this._super.apply(this, arguments);

      var ENV = Ember.getOwner(this).resolveRegistration('config:environment');
      var siteKey = Ember.get(ENV, 'ember-cli-google.recaptcha.siteKey');

      Ember.assert('Missing ember-cli-google.recaptcha.siteKey in config/environment.', !!siteKey);

      this.set('_siteKey', siteKey);
    },


    /// Site key for the application.
    siteKey: Ember.computed.readOnly('_siteKey'),

    /**
     * Renders the container as a reCAPTCHA widget and returns the ID of the newly
     * created widget.
     *
     * @param container
     * @param params
     * @returns {RSVP.Promise|*}
     */
    render: function render(container, params) {
      var _this = this;

      var siteKey = this.get('_siteKey');
      var options = Ember.merge({ sitekey: siteKey }, params);

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this.get('_instance').then(function (grecaptcha) {
          var widgetId = grecaptcha.render(container, options);

          Ember.run(null, resolve, widgetId);
        }).catch(reject);
      });
    },


    /**
     * Manually invoke the reCAPTCHA check. Used if the invisible reCAPTCHA is on a
     * div instead of a button.
     *
     * @param widgetId
     * @returns {RSVP.Promise|*}
     */
    execute: function execute(widgetId) {
      var _this2 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this2.get('_instance').then(function (grecaptcha) {
          grecaptcha.execute(widgetId);

          Ember.run(null, resolve);
        }).catch(reject);
      });
    },


    /**
     * Resets the reCAPTCHA widget.
     *
     * @param widgetId
     * @returns {RSVP.Promise|*}
     */
    reset: function reset(widgetId) {
      var _this3 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this3.get('_instance').then(function (grecaptcha) {
          grecaptcha.reset(widgetId);

          Ember.run(null, resolve);
        }).catch(reject);
      });
    },


    /**
     * Gets the response for the reCAPTCHA widget.
     *
     * @param widgetId
     * @returns {RSVP.Promise|*}
     */
    getResponse: function getResponse(widgetId) {
      var _this4 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this4.get('_instance').then(function (grecaptcha) {
          var res = grecaptcha.getResponse(widgetId);
          Ember.run(null, resolve, res);
        }).catch(reject);
      });
    },


    /**
     * Get the singleton grecaptha instance from the window. If the instance does
     * not exist, it is installed by downloading the recaptcha script from online.
     */
    _instance: new Ember.RSVP.Promise(function (resolve, reject) {
      // Install the global callback.
      window._grecaptcha_onload = function () {
        Ember.run(null, resolve, window.grecaptcha);
      };

      Ember.$(window).ready(function () {
        Ember.$.getScript('https://www.google.com/recaptcha/api.js?onload=_grecaptcha_onload&render=explicit').fail(function (xhr) {
          Ember.run(null, reject, xhr);
        });
      });
    })
  });
});