define('ember-sort-filter-table/helpers/is-primitive', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isPrimitive = isPrimitive;


  /**
    Signal if a value is primitive type (exluding null and undefined)
  
    @helper isPrimitive
    @private
    @returns Bool
  */
  function isPrimitive(params /*, hash*/) {
    var val = params[0];
    return typeof val === 'string' || typeof val === 'boolean' || typeof val === 'number';
  }

  exports.default = Ember.Helper.helper(isPrimitive);
});