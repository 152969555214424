define('ember-rapid-forms/components/em-text', ['exports', 'ember', 'ember-rapid-forms/components/em-form-group'], function (exports, _ember, _emberRapidFormsComponentsEmFormGroup) {

  /*
  Form Text Area
  
  Syntax:
  {{em-text property="property name" rows=4 cols=40}}
   */
  exports['default'] = _emberRapidFormsComponentsEmFormGroup['default'].extend({
    elementClass: null,
    htmlComponent: 'erf-html-text',
    property: null,
    label: null,
    name: null,
    placeholder: null,
    rows: null,
    cols: null,
    required: null,
    autofocus: null,
    readonly: null,
    autoresize: null,
    disabled: null,
    controlWrapper: _ember['default'].computed('form.formLayout', function () {
      if (this.get('form.formLayout') === 'horizontal') {
        return 'col-sm-10';
      }
      return null;
    })
  });
});