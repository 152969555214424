define("ember-popper/templates/components/ember-popper", ["exports"], function (exports) {
  "use strict";

  exports.__esModule = true;
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 7,
            "column": 0
          }
        },
        "moduleName": "ember-popper/templates/components/ember-popper.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "yield", [["subexpr", "hash", [], ["disableEventListeners", ["subexpr", "action", ["disableEventListeners"], [], ["loc", [null, [2, 24], [2, 56]]], 0, 0], "enableEventListeners", ["subexpr", "action", ["enableEventListeners"], [], ["loc", [null, [3, 23], [3, 54]]], 0, 0], "scheduleUpdate", ["subexpr", "action", ["scheduleUpdate"], [], ["loc", [null, [4, 17], [4, 42]]], 0, 0], "update", ["subexpr", "action", ["update"], [], ["loc", [null, [5, 9], [5, 26]]], 0, 0]], ["loc", [null, [1, 8], [6, 1]]], 0, 0]], [], ["loc", [null, [1, 0], [6, 3]]], 0, 0]],
      locals: [],
      templates: []
    };
  }());
});