define('ember-sort-filter-table/components/sf-filter', ['exports', 'ember-sort-filter-table/templates/components/sf-filter'], function (exports, _sfFilter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _sfFilter.default,
    tagName: 'span',
    classNames: ['sf-table--filter'],
    placeholder: 'Filter',
    actions: {
      /**
        * Clear the current filter query
        *
        * @method clearField
        * @private
        */
      clearField: function clearField() {
        Ember.set(this, 'group.groupQuery', null);
      }
    }
  });
});