define("ember-rapid-forms/templates/components/control-within-label", ["exports"], function (exports) {
  "use strict";

  exports.__esModule = true;
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 4,
              "column": 0
            }
          },
          "moduleName": "ember-rapid-forms/templates/components/control-within-label.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "form-group-control", [], ["controlWrapper", ["subexpr", "@mut", [["get", "controlWrapper", ["loc", [null, [2, 40], [2, 54]]], 0, 0, 0, 0]], [], [], 0, 0], "mainComponent", ["subexpr", "@mut", [["get", "mainComponent", ["loc", [null, [3, 20], [3, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "form", ["subexpr", "@mut", [["get", "form", ["loc", [null, [3, 39], [3, 43]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [2, 4], [3, 45]]], 0, 0]],
        locals: [],
        templates: []
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 5,
            "column": 0
          }
        },
        "moduleName": "ember-rapid-forms/templates/components/control-within-label.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "em-form-label", [], ["text", ["subexpr", "@mut", [["get", "label", ["loc", [null, [1, 22], [1, 27]]], 0, 0, 0, 0]], [], [], 0, 0], "horiClass", "", "inlineClass", "", "form", ["subexpr", "@mut", [["get", "form", ["loc", [null, [1, 61], [1, 65]]], 0, 0, 0, 0]], [], [], 0, 0], "for", ["subexpr", "@mut", [["get", "mainComponent.id", ["loc", [null, [1, 70], [1, 86]]], 0, 0, 0, 0]], [], [], 0, 0], "extraClass", ["subexpr", "@mut", [["get", "extraClass", ["loc", [null, [1, 98], [1, 108]]], 0, 0, 0, 0]], [], [], 0, 0]], 0, null, ["loc", [null, [1, 0], [4, 18]]]]],
      locals: [],
      templates: [child0]
    };
  }());
});