define('ember-sort-filter-table/helpers/hash-contains', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.hashContains = hashContains;
  var stringify = JSON.stringify;
  function hashContains(params) {
    // Stringify the hash
    var _hash = params[0];
    var str = stringify(_hash);

    // Convert the query to a regex
    var query = params[1] || '';
    var rgx = new RegExp(query, 'i');

    // Returns truthy when str was found in hash
    return rgx.test(str);
  }

  exports.default = Ember.Helper.helper(hashContains);
});