define('ember-sort-filter-table/components/sf-rows', ['exports', 'ember-sort-filter-table/templates/components/sf-rows', 'ember-decorators/object'], function (exports, _sfRows, _object) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _desc, _value, _obj;

  exports.default = Ember.Component.extend((_dec = (0, _object.computed)('rows'), (_obj = {
    layout: _sfRows.default,
    tagName: '',

    _rows: function _rows(rows) {
      if (rows && rows.length) {
        return rows.map(function (item) {
          return Ember.assign({}, item);
        });
      } else {
        return [];
      }
    }
  }, (_applyDecoratedDescriptor(_obj, '_rows', [_dec], Object.getOwnPropertyDescriptor(_obj, '_rows'), _obj)), _obj)));
});