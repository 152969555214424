define('ember-cli-phone-number/components/phone-number', ['exports', 'ember'], function (exports, _ember) {
    'use strict';

    exports.__esModule = true;

    var _ember2 = _interopRequireDefault(_ember);

    function _interopRequireDefault(obj) {
        return obj && obj.__esModule ? obj : {
            default: obj
        };
    }

    exports.default = _ember2.default.Component.extend({
        validate: function (strCheck, e) {
            var unicode = e.charCode ? e.charCode : e.keyCode;
            if (strCheck.indexOf(unicode) === -1) {
                e.preventDefault();
            }
        },
        didInsertElement: function () {
            var self = this;
            this.$('input').mask('999-999-9999');
            this.$('input').keypress(function (event) {
                self.validate('13,48,49,50,51,52,53,54,55,56,57,45', event);
            });
        }
    });
});